<template>
  <div
    class="
      flex
      flex-col
      h-screen
      layout
      layout-view
      w-screen
    "
  >
    <!-- ヘッダー -->
    <header
      class="
        common-background-pink
        fixed
        w-full
        z-layouts-header
      "
    >
      <common-header-manager
        :class-name="data.className"
        :page-title="data.title"
        :reload-time="data.reloadTime"
        :top-page-flg="data.topPageFlg"
        :app-status="useAppStatusStore()"
        :selected-org="selectedOrg"
        @history-back="historyBack(router)"
        @logout="confirmLogout()"
        @reload-screen="async () => await reloadScreen()"
        @show-select-classroom-modal="showSelectClassroomModal()"
      >
      </common-header-manager>
    </header>
    <!-- コンテンツ -->
    <main
      class="
        flex-1
      "
    >
      <slot />
    </main>
    <!-- フッター -->
    <footer
      class="
        bottom-0
        common-background-pink
        fixed
        w-full
        z-layouts-footer
      "
    >
      <common-global-nav-manager
        :page-type="data.pageType"
        :isDisagreeMenu="selectedOrg.disagreeFlg === '1'"
      >
      </common-global-nav-manager>
    </footer>
    <!-- 教室切替モーダル -->
    <page-members-select-classroom-modal
      :close-btn-flg="true"
      :org-list="useAppStatusStore().getOrgList()"
      :selected-org="selectedOrg"
      v-if="isSelectClassroomModal"
      @close="($event: Event) => {closeSelectClassroomModal()}"
      @submit="async ($event: Organization) => {
        await submitSelectClassroomModal($event)
      }"
    >
    </page-members-select-classroom-modal>
    <common-loading
      v-if="isLoading"
    />
    <common-modal-error
      v-if="isError"
      :error-title="errorTitle"
      :error-message="errorMessage"
      :error-code="errorCode"
      role="manager"
      @close="($event: boolean) => closeErrorModal($event)"
    >
    </common-modal-error>
    <common-modal-logout
      v-if="isShowLogoutModal"
      @cancel="closeConfirmModal()"
      @logout="logoutEvent()"
      role="manager"
    >
    </common-modal-logout>
  </div>
</template>

<script setup lang="ts">
  import { type Ref, ref, reactive, watch, onMounted, provide } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import type { RouteLocationNormalizedLoaded } from 'vue-router'
  import { useAppStatusStore } from '~/store/pinia/app-status'
  import { historyBack } from '~/libs/historyBack'
  import { logout } from '~/libs/logout'
  import type { ManagerPageData } from '~~/types/managerPageData'
  import type { ManagerPageType } from '~~/types/managerPageType'
  import LoginApiService from '~/services/LoginApiService'
  import type { Organization } from '~~/types/organization'
  import { storeSettingManager } from '~/libs/storeSetting'
  import { modalDisplaySwitching } from '~/libs/modalDisplaySwitching'
  import { layoutsSetting } from '~/libs/layoutsSetting'
  import { updateIsLoadingKey } from '~/const/updateIsLoadingKey'
  import { updateErrorCodeKey, updateErrorMessageKey, updateErrorTitleKey, updateIsErrorKey } from '~/const/updateErrorKey'
  import { DateUtil } from '~/utils/date-util'
  import { getIsBackgroundLoadingKey } from '~/const/getIsBackgroundLoadingKey'
  import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
  import * as Sentry from '@sentry/vue'
  import { httpInterceptors } from '~/libs/httpInterceptors'
  import { checkVisibilityChange } from '~/utils/check-visibility-change'

  const router = useRouter()
  const route = useRoute()

  checkVisibilityChange('1')

  watch(route, (to) => {
    setPageInfo(to)
    const mainElement = document.getElementsByTagName('main')[0]
    mainElement.style.paddingTop = data.topPageFlg ? topPagePaddingTop.value : subPagePaddingTop.value
  })

  const data: ManagerPageData = reactive({
    className: '',
    pageType: 'home',
    reloadTime: new DateUtil().date,
    title: '',
    topPageFlg: false,
  })

  if (useAppStatusStore().getOrgList().length == 0) {
    await useAppStatusStore().loadInitAppStatus()
  }

  const selectedOrg: Ref<Organization> = ref(useAppStatusStore().getSelectedOrg())

  const setPageInfo = (route: RouteLocationNormalizedLoaded) => {
    selectedOrg.value = useAppStatusStore().getSelectedOrg()
    document.title = route?.meta?.title as string
    data.title = route?.meta?.title as string
    data.pageType = route?.meta?.pageType as ManagerPageType
    data.reloadTime = route?.meta?.reloadTime as Date
    data.topPageFlg = route?.meta?.topPageFlg as boolean
    data.className = selectedOrg.value != null ? selectedOrg.value.name : ''
  }

  setPageInfo(route)

  const submitSelectClassroomModal = async (org: Organization) => {
    useAppStatusStore().setSelectedOrgId(org.id)
    await LoginApiService.selectClassroom(org.id).then((response) => {
      if (response.data.code != 0) {
        // TODO: APIエラー
        updateIsError?.(true)
        updateErrorCode?.(response.data.code.toString())
      } else {
        if (response.headers.token !== undefined) {
          sessionStorage.setItem("token", response.headers.token as string)
        }
      }
    })
    .catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    })
    closeSelectClassroomModal()
    await reloadScreen()
  }

  const isShowLogoutModal = ref(false)
  const confirmLogout = () => {
    modalDisplaySwitching(isShowLogoutModal, true)
  }
  const closeConfirmModal = () => {
    modalDisplaySwitching(isShowLogoutModal, false)
  }
  const logoutEvent = async () => {
    await logout(router)

    closeConfirmModal()
  }

  const reloadScreen = async () => {
    // storeを再設定
    await storeSettingManager()
    location.reload()
  }

  const isSelectClassroomModal = ref(false)

  const showSelectClassroomModal = () => {
    modalDisplaySwitching(isSelectClassroomModal, true)
  }

  const closeSelectClassroomModal = () => {
    modalDisplaySwitching(isSelectClassroomModal, false)
  }

  const topPagePaddingTop = ref('')
  const subPagePaddingTop = ref('')

  onMounted(() => {
    if (data.topPageFlg) {
      // refresh-barがない場合のpadding算出用に一時的に非表示
      document.getElementsByClassName('refresh-bar')[0].classList.add('hidden')
      subPagePaddingTop.value = layoutsSetting(true, true)
      // 算出後に再表示
      document.getElementsByClassName('refresh-bar')[0].classList.remove('hidden')
      topPagePaddingTop.value = layoutsSetting(true, false)
    } else {
      // refresh-barがない場合のpadding算出用に一時的に表示
      document.getElementsByClassName('refresh-bar')[0].classList.remove('hidden')
      topPagePaddingTop.value = layoutsSetting(true, false)
      // 算出後に再表示
      document.getElementsByClassName('refresh-bar')[0].classList.add('hidden')
      subPagePaddingTop.value = layoutsSetting(true, true)
    }
  })

  const isLoading = ref(false)
  const updateIsLoading = (b: boolean) => isLoading.value = b
  provide(updateIsLoadingKey, updateIsLoading)

  const isError = ref(false)
  const updateIsError = (b: boolean) => isError.value = b
  provide(updateIsErrorKey, updateIsError)

  const errorTitle = ref('')
  const updateErrorTitle = (s: string) => errorTitle.value = s
  provide(updateErrorTitleKey, updateErrorTitle)

  const errorMessage = ref('')
  const updateErrorMessage = (s: string) => errorMessage.value = s
  provide(updateErrorMessageKey, updateErrorMessage)

  const errorCode = ref('')
  const updateErrorCode = (s: string) => errorCode.value = s
  provide(updateErrorCodeKey, updateErrorCode)

  const isBackgroundLoading: Map<string, boolean> = reactive(new Map<string, boolean>())
  const updateIsBackgroundLoading = (key: string, b: boolean) => isBackgroundLoading.set(key, b)
  provide(updateIsBackgroundLoadingKey, updateIsBackgroundLoading)
  const getIsBackgroundLoading = () => isBackgroundLoading
  provide(getIsBackgroundLoadingKey, getIsBackgroundLoading)

  const closeErrorModal = async (isSystemError: boolean) => {
    updateIsError?.(false)
    updateErrorTitle?.('')
    updateErrorMessage?.('')
    updateErrorCode?.('')
    if (isSystemError) {
      await logout(router)
    }
  }

  router.onError((error) => {
    const errorString: string = error.toString()
    const regex = /dynamically imported module/
    if (regex.test(errorString)) {
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  })

  watch(() => isBackgroundLoading.get('loadInitAppStatus'), () => {
    // AppStatus取得完了を検知してsetPageInfoを実行
    setPageInfo(route)
  })

  httpInterceptors(router)
</script>
